import {BaseGraph} from "../base_graph";

class WorkingTimeDirectiveAverageHours extends BaseGraph {
    constructor() {
        super('wtd_average_hours');
    }

    getChartJsOptions(options, data) {
        options.indexAxis = 'y';
        options.plugins = {
            legend: {
                display: false
            },
            annotation: {       // Mark the limits for 48 hours (average max) and 60 hours (absolute max)
                annotations: {
                    fortyeight: {
                        type: 'line',
                        borderColor: '#F28900',
                        value: 48,
                        xMin: 48,
                        xMax: 48,
                    },
                    sixty: {
                        type: 'line',
                        borderColor: '#CF5757',
                        value: 60,
                        xMin: 60,
                        xMax: 60,
                    }
                }
            }
        };
        return options;
    }
}

$(() => {
    if(app && app.DASHBOARD_CORE) {
        new WorkingTimeDirectiveAverageHours();
    } else {
        console.warn('DashboardCore not initalized.');
    }
})